import { useEffect, useState } from "react";

export default function ApiDolar (){
    
    const [dolar, setDolar] = useState([]);
    const [euro, setEuro] = useState([]);

    useEffect(() => {
        fetchCurrency();
    }, []);
    
    const fetchCurrency = async () => {
        try {
            const response = await fetch('https://admin.leiten.com.ar/api/getCurrencyNames');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setDolar(data[0].value);
            setEuro(data[1].value);
        } catch (error) {
            console.error('Error fetching currency:', error);
        }
    };

    return {dolar, euro};
}