import { useEffect, useState } from "react";

export default function ApiBranch() {
    const [branch, setBranch] = useState([]); // ESTO ES PARA USAR CON API DE Branch.

    useEffect(() => {
        fetchBranch(); // ESTO ES PARA USAR CON API DE Branch.
    }, []);

    // ESTO ES PARA USAR CON API DE Branch.
    const fetchBranch = async () => {
        try {
            const response = await fetch('https://admin.leiten.com.ar/api/getAllBranches');
            if (response.ok) {
                const data = await response.json();
                const branches = data.slice(1); // Excluye el primer elemento
                setBranch(branches); // Establece Sucursales obtenidos desde la API en el estado
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error fetching currency:', error);
        }
    };

    return branch;
}