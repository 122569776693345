import { Outlet } from 'react-router-dom';
import Footer from '../partials/Footer';
import Header from '../partials/Header';

export default function LayoutSimple(){
    return(
        <>
            <div id='header'>
                <Header/>
            </div>
            <Outlet/>
            <Footer/>
        </>
    )
};