import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export default function GallerySimple (props) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>

            <div className="row">
                {props.gallery.map((data) => (
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-2" key={data.id}>
                        {windowWidth >= 992 ? (
                            <Link to='#!' data-bs-toggle="modal" data-bs-target={'#modalImageGallery' + data.id}>
                                <img className="w-100 mb-4 rounded" src={data.img} alt={data.alt} />
                            </Link>
                        ) : (
                            <img className="w-100 mb-4 rounded" src={data.img} alt={data.alt} />
                            )}

                        {windowWidth >= 992 && (
                            <div tabIndex='-1' aria-labelledby={"modalImageGallery" + data.id} aria-hidden='true' className="modal fade" id={"modalImageGallery" + data.id}>
                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-centent">
                                        <img src={data.img} alt={data.alt} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

        </>
    );
}