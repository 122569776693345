import ApiProducts from "../../service/ApiProducts";
import Styled from "styled-components";
import Spinner from "../spinner/Spinner";
import PuntalList from "../columnPuntal/PuntalList";

export default function ColumnPuntal() {
    const urlSoporte = 'https://sinis.com.ar/api/grupos-sistemas/grupo/sistema/3/productos';

    const { products, loading } = ApiProducts({ url: urlSoporte });
    const img = '../../../assets/img/products/puntal.jpeg';
    const alt = 'Imagen de Puntual'

    if (loading) {
        return <Spinner />;
    }

    return (
        //<Spinner />
        <StyleColumnaCircular>
            <PuntalList puntales={products} img={img} alt={alt}/>
        </StyleColumnaCircular>
    );
}

const StyleColumnaCircular = Styled.div`

`