import styled from "styled-components";
import ApiBranch from "../../service/ApiBranchs";

export default function Maps(){

    const branches = ApiBranch();

    return(
        <StyledMaps>

        <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 contenInfo">
                        {branches.slice(0, Math.ceil(branches.length / 2)).map((branch) => (
                            <li className="contenSucursal" key={branch.id}>
                                <p className='title'><strong>{branch.nombre}</strong></p>
                                <p>{branch.direccion} - {branch.localidad} - {branch.prov.nombre}</p>
                                <p>{branch.tel_fijo} / {branch.tel_movil}</p>
                                <iframe className="contenMap" title={branch.nombre} src={branch.coordenadas} width="auto" height="auto" style={{border:"0"}} allowFullScreen="" loading="lazy"></iframe>
                            </li>
                        ))}
                    </div>
                    <div className="col-12 col-lg-6 contenInfo">
                        {branches.slice(Math.ceil(branches.length / 2)).map((branch) => (
                            <li className="contenSucursal" key={branch.id}>
                                <p className='title'><strong>{branch.nombre}</strong></p>
                                <p>{branch.direccion} - {branch.localidad} - {branch.prov.nombre}</p>
                                <p>{branch.tel_fijo} / {branch.tel_movil}</p>
                                <iframe className="contenMap" title={branch.nombre} src={branch.coordenadas} width="auto" height="auto" style={{border:"0"}} allowFullScreen="" loading="lazy"></iframe>
                            </li>
                        ))}
                    </div>
                </div>
            </div>
                <div className="linea"></div>
        </StyledMaps>
    )
}

const StyledMaps = styled.div`
    .contenInfo{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0rem;
        @media(min-width: 768px){
            margin-top: 0rem;
        }
    }
    .containerMap{
        text-align: center;
        @media(min-width: 768px){
            text-align: end;
        }
    }
    .contenMap{
        padding: 1rem;
        max-width: 100%;
        max-height: 100%;
    }
    iframe{
        padding: 1rem 0rem;
    }
    p{
        font-size: 0.9rem;
    }
`