import { useState } from "react";

import styled from "styled-components";
import ColumnPuntal from "../columnsAccessory/ColumnPuntal";

export default function Puntal(){

    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentSelection = (component) => {
        setSelectedComponent(component);
    };

    return(
        <StyledPuntal>
            <div className="container" style={{marginBottom: '5rem'}}>
                <div className="row" style={{marginTop: '3rem', textAlign: 'center'}}>
                    <div className="col-6 col-lg-3" >
                        <div className="contenImg">
                            <img src="../../../assets/img/products/zuncho.jpeg" alt="zuncho" />
                        </div>
                        <h4>Puntales Inclinados</h4>
                        <button className="btn btn-primary" onClick={() => handleComponentSelection(<ColumnPuntal />)}>Ver Más</button> 
                    </div>
                               
                </div>
            </div>
            {selectedComponent && (
                <div className="">
                    <div className="row" style={{margin: '0px'}}>
                        <div className="col-12">
                            {selectedComponent}
                        </div>
                    </div>
                </div>
            )}
        </StyledPuntal>
    )
}

const StyledPuntal = styled.div`
    .contenImg{
        margin-bottom: 2rem;
        width: 100%;
    }
    img {
        width: 100%;
    }
    .btn-primary{
        margin-top: 1rem;
        background-color: var(--colorPrincipal);
        color: #000;
        border: none;
    }
`