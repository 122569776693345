import './App.css';
import { Route, Routes } from 'react-router-dom';

import LayoutSimple from './layouts/variations/LayoutSimple';

//VISTAS DE LANDING
import HomeView from './views/landing/HomeView';
import SystemView from './views/landing/SystemView';
import MountingView from './views/landing/MountingView';
import ColumnView from './views/landing/ColumnView';
import UsView from './views/landing/UsView';

export default function App() {
  return (
    <Routes>  
      {/* RUTAS PARA FRONT CLIENTE */}
      <Route path="/" element={<LayoutSimple />}>
        <Route index element={<HomeView />} />
        <Route path="sistema" element={<SystemView/>} />
        <Route path="montaje" element={<MountingView/>} />
        <Route path="columnas" element={<ColumnView/>} />
        <Route path="nosotros" element={<UsView/>} />
      </Route>
    </Routes>
  );
}