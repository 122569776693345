import Styled from "styled-components";
import Carousel from "../../component/carousel/Carousel";
import Characteristics from "../../component/characteristics/Characteristics";
import Columns from "../../component/columns/Columns";
import Gallery from "../../component/galleryImg/Gallery";


export default function HomeView(){
    return(
        <StyleHomeView>
            <Carousel/>
            <Characteristics/>
            <div className="linea"></div>
            <Columns/>
            <Gallery/>
        </StyleHomeView>
    )
}

const StyleHomeView = Styled.div`

`