import { useState } from "react";

import styled from "styled-components";
import ColumnCircular from "./ColumnCircular";
import ColumnCuadrada from "./ColumnCuadrada";
import ColumnMedianera from "./ColumnMedianera";
import ColumnEsquinera from "./ColumnEsquinera";

export default function Columns(){

    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentSelection = (component) => {
        setSelectedComponent(component);
    };

    return(
        <StyledColumns>
            <div className="container" style={{marginBottom: '5rem'}}>
                <div className="row">
                    <div className="col-12">
                        <h2 className="contenTitle">SOLUCIONES DE ENCOFRADOS ENCOMAX</h2>
                    </div>
                </div>
                <div className="row" style={{marginTop: '3rem', textAlign: 'center'}}>
                    <div className="col-6 col-lg-3" >
                        <div className="contenImg">
                            <img src="../../../assets/img/products/circular.jpeg" alt="columna circular" />
                        </div>
                        <h4>Columna Circular</h4>
                        <button className="btn btn-primary" onClick={() => handleComponentSelection(<ColumnCircular />)}>Ver Más</button> 
                    </div>
                
                    <div className="col-6 col-lg-3">
                        <div className="contenImg">
                            <img src="../../../assets/img/products/cuadrada.jpeg" alt="columna cuadrada" />
                        </div>
                            <h4>Columna Cuadradas</h4>
                            <button className="btn btn-primary" onClick={() => handleComponentSelection(<ColumnCuadrada />)}>Ver Más</button>
                    </div>
                               
                    <div className="col-6 col-lg-3">
                        <div className="contenImg">
                            <img src="../../../assets/img/products/medianera.jpeg" alt="columna medianera" />
                        </div>
                            <h4>Columna Medianera</h4>
                            <button className="btn btn-primary" onClick={() => handleComponentSelection(<ColumnMedianera />)}>Ver Más</button>
                    </div>

                    <div className="col-6 col-lg-3">
                        <div className="contenImg">
                            <img src="../../../assets/img/products/esquinera.jpeg" alt="columna esquinera" />
                        </div>
                            <h4>Columna Esquineras</h4>
                            <button className="btn btn-primary" onClick={() => handleComponentSelection(<ColumnEsquinera />)}>Ver Más</button>
                    </div>
                               
                </div>
            </div>
            {selectedComponent && (
                <div className="">
                    <div className="row" style={{margin: '0px'}}>
                        <div className="col-12">
                            {selectedComponent}
                        </div>
                    </div>
                </div>
            )}
        </StyledColumns>
    )
}

const StyledColumns = styled.div`
    .contenImg{
        margin-bottom: 2rem;
        width: 100%;
    }
    img {
        width: 100%;
    }
    .btn-primary{
        margin-top: 1rem;
        background-color: var(--colorPrincipal);
        color: #000;
        border: none;
    }
`