import ApiProducts from "../../service/ApiProducts";
import Styled from "styled-components";
import Spinner from "../spinner/Spinner";
import AccessoryList from "../columnsAccessory/AccessoryList";

export default function AccessoryZuncho() {
    const urlZuncho = 'https://sinis.com.ar/api/grupos-sistemas/grupo/sistema/37/productos';

    const { products, loading } = ApiProducts({ url: urlZuncho });
    const img = '../../../assets/img/products/zuncho.jpeg';
    const alt = 'Imagen de Zuncho usado en el sistema encomax'

    if (loading) {
        return <Spinner />;
    }

    return (
        //<Spinner />
        <StyleColumnaCircular>
            <AccessoryList accessorys={products} img={img} alt={alt}/>
        </StyleColumnaCircular>
    );
}

const StyleColumnaCircular = Styled.div`

`