import { useEffect, useState } from "react";

export default function ApiProducts (props) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const url = props.url; 

    useEffect(() => {
        const fetchProductsColumns = async () => {
            try {
                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    setProducts(data);
                } else {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchProductsColumns();
    }, [url]);

    return { products, loading };
}