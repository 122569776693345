import Styled from "styled-components";

export default function Carousel(){
    return(
        <StyledCarousel>
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner caroselPersonalizado">
                    <div className="carousel-item active">
                        <div className="container">
                            <div className="slogan">
                                <h2>La solución de encofrados más rápida para pilares.</h2>
                                <p>Una sola pieza, sin ensamble, súper liviana y de fácil nivelación.</p>
                            </div>
                        </div>
                        <img src="../../../assets/img/banner/slider01.jpg" className="d-block w-100" alt="imagen pincipal home"/>
                    </div>
                    <div className="carousel-item">
                    <div className="container">
                            <div className="slogan">
                                <h2>Encofrados descartables para pilares.</h2>
                                <p>Una vez desencofrado, Encomax puede ser utilizado como funda de protección evitando manchas, golpes y deterioro de la columna.</p>
                            </div>
                        </div>
                        <img src="../../../assets/img/banner/slider02.jpg" className="d-block w-100" alt="imagen pincipal home"/>
                    </div>
                    <div className="carousel-item">
                    <div className="container">
                            <div className="slogan">
                                <h2>El sistema de encofrado más liviano.</h2>
                                <p>Una sola pieza, sin ensamble, súper liviana y de fácil nivelación.</p>
                            </div>
                        </div>
                        <img src="../../../assets/img/banner/slider03.jpg" className="d-block w-100" alt="imagen pincipal home"/>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </StyledCarousel>
    )
}

const StyledCarousel = Styled.div`
    .caroselPersonalizado {
        @media (min-width: 992px){
            height: var(--AltoCarrusel);
        }
    }
    .slogan{
        display: none;
        @media (min-width: 992px){
            display: flex;
            position: absolute;
            z-index: 2;
            color: #fff;
            margin: 0 auto;
            height: var(--AltoCarrusel);
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            /* background-color: rgba(211, 211, 211, 0.2);
            padding: 5rem; */
        }

    }
    h2, p{
        display: none;
        @media (min-width: 768px){
            display: block;
            color: #fff;
        }
    }
    h2{
        font-size: 2rem;
    }
`