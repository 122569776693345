import Styled from "styled-components";

export default function Spinner(){
    return (
        <StyleSpinner>
            <div className="contaniner">

            <div className="containerSpinner">
                <div className="contenSpinner">
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Cargando...</span>
                    </div>
                    <h4>Cargando...</h4>
                </div>
            </div>
            </div>
        </StyleSpinner>
    )
}

const StyleSpinner = Styled.div`
    .containerSpinner{
        background-color: var(--colorGrisSecundario);
        // border-radius: 0.7rem;
        padding: 10rem;
    }
    .contenSpinner{
        //background-color: var(--colorFondoPrincipal);
        padding: 4rem;
        display: flex;
        border-radius: 2rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    h4{
     
        color: var(--colorPrincipal);
    }
`