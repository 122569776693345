import styled from "styled-components";
import ApiBranch from "../../service/ApiBranchs";

export default  function Footer() {

    const branches = ApiBranch();

    return(
        <StyledFooter>
            <footer className="containerFooter">
                <div className="contenFooter container">
                    <div className="contenInfo">
                        <h5>Comuníquese con Encomax®</h5>
                        <div className="row">
                            <div className="col-6">
                                <ul style={{paddingLeft: '0px'}}>
                                    {branches.slice(0, Math.ceil(branches.length / 2)).map((branch, id) => (
                                        <li className="contenSucursal" key={branch.id}>
                                            <p className='title'><strong>{branch.nombre}</strong></p>
                                            <p>{branch.direccion} - {branch.localidad} - {branch.prov.nombre}</p>
                                            <p>{branch.tel_fijo} / {branch.tel_movil}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-6">
                                <ul style={{paddingLeft: '0px'}}>
                                    {branches.slice(Math.ceil(branches.length / 2)).map((branch, id) => (
                                        <li className="contenSucursal" key={branch.id}>
                                            <p className='title'><strong>{branch.nombre}</strong></p>
                                            <p>{branch.direccion} - {branch.localidad} - {branch.prov.nombre}</p>
                                            <p>{branch.tel_fijo} / {branch.tel_movil}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </StyledFooter>
    )
};

const StyledFooter = styled.footer`
    .containerFooter{
        background-image: url('../../../assets/img/footer2.jpg');
        @media(min-width: 768px){
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 90%;
            margin-top: 5rem;
        }
    }
    .contenFooter{
        color: #fff;
        display: flex;
        justify-content: flex-end;
    }
    .contenInfo{
        background-color: var(--colorGRisTerciario);
        padding: 2rem;
        opacity: 0.9;
        @media(min-width: 768px) {
            padding: 4rem;
        }
    }
    .containerSucursal{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .contenSucursal p{
        color: #fff;
        font-size: 0.7rem;
        padding: 0.5rem;
        @media (min-width: 768px){
            font-size: 0.8rem;
        }
    }
    .title {
        margin-top: 40px;
    }
    .title strong{
        font-size: 1.2rem;
    }
    strong, h5{
        color: #fff;
    }
`