import ApiProducts from "../../service/ApiProducts";
import Styled from "styled-components";
import Spinner from "../spinner/Spinner";
import ColumnList from "./ColumnList";

export default function ColumnCircular() {
    const urlCirculares = 'https://sinis.com.ar/api/grupos-sistemas/grupo/sistema/33/productos';

    const { products, loading } = ApiProducts({ url: urlCirculares });
    const img = '../../../assets/img/products/circular.jpeg';

    if (loading) {
        return <Spinner />;
    }

    return (
        //<Spinner />
        <StyleColumnaCircular>
            <ColumnList columns={products} img={img} />
        </StyleColumnaCircular>
    );
}

const StyleColumnaCircular = Styled.div`

`