import React from 'react';
import Styled from "styled-components";
import ApiDolar from '../../service/ApiDolar';

const ColumnList = ({ columns, img }) => {
    
    const {dolar, euro} = ApiDolar();

    const toThousand = n => {
        const parts = n.toString().split('.');
        const integerPart = parts[0];
        const decimalPart = parts[2] || '';
        
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const result = decimalPart ? `${formattedInteger},${decimalPart}` : formattedInteger;
        
        return result;
    };

    return(
        <StyleColumnList>
            <div className="scroll container">
                <div className="contenedorTabla">
                    <table class="table table-striped tablaPrecio">
                        <thead>
                            <tr className='table-dark'>
                                <th scope="col"></th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Código</th>
                                <th scope="col">Material</th>
                                <th scope="col">Largo</th>
                                <th scope="col">Venta Dolar</th>
                                <th scope="col">Venta Pesos</th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            {columns.map(column => (
                                <tr key={column.id}>
                                    <th><img className='imgProduct' src={img} alt='Imagen producto'></img></th>
                                    <td>{column.nombre_producto}</td>
                                    <td>{column.codigo}</td>
                                    <td>ABS + Fibra</td>
                                    <td>1 metro</td>
                                    <td>U$S {toThousand((column.prices[0].precio_venta*euro)/dolar)} +iva</td>
                                    <td>$ {toThousand(((column.prices[0].precio_venta*euro)/dolar) * dolar)} +iva</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="iconScroll">
                        <i className="fa-solid fa-left-right"></i>
                    </div>
                </div>
            </div>
        </StyleColumnList>
    );
};

export default ColumnList;

const StyleColumnList = Styled.div`
    .iconScroll{
        display: flex;
        justify-content: center;
        color: var(--colorGrisSecundario);
        @media (min-width: 992px){
            display:none;
        }
    }
    .scroll{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 2rem;
        @media(min-width: 992px){
            display: flex;
            align-items: center;
        }
    }
    .contenedorTabla::-webkit-scrollbar{
        height: 15px;
        border-radius: 10px;
        background-color: var(--colorPrincipal);
        @media (min-width: 992px){
            display: none;
        }
    }
    .contenedorTabla::-webkit-scrollbar-thumb{
        background-color: var(--colorGrisSecundario);
        border-radius: 10px;
        width: 5px;
    }
    .contenedorTabla{
        overflow-x: scroll;
        flex-direction: column;
        width: 100%;
        @media (min-width: 992px){
            width: 100%;
            display: flex;
            flex-direction: column ;
            justify-content: space-between;
            ::-webkit-scrollbar {display:none;} //Oculta la barra de scroll sin perder la funcion
        }
    }
    
    .imgProduct{
        width: 50px;
        @media (min-width: 992px){
            width: 100px;
        }
    }
`