import Banner from "../../component/banner/Banner";
import GallerySimple from "../../component/galleryImg/GallerySimple";

export default function SystemView (){

    return (
        <>
            <Banner banner={banner}/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="contenTitle" style={{margin: '3rem 0rem'}}>EL PODER DE LA INNOVACIÓN</h2>
                        <p><strong>ENCOMAX FIBRA</strong> es un encofrado para pilares de sección circular, patentado mundialmente, suponiendo la evolución tecnológica de los encofrados de cartón o de brick. Los encofrados se fabrican entre Ø 250 y Ø 1200 (intervalo de 50) y con alturas estandar de 3 y 4 metros, pudiéndose fabricar otras dimensiones de forma no estandar.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid" style={{margin: '3rem 0rem'}}>
                <GallerySimple gallery={galleryImg}/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h5>COMPOSICIÓN</h5>
                        <p>Se trata de un encofrado desechable formado por una lámina plastificada (DM) y un refuerzo exterior de fibra de vidrio encargado de resistir la del hormigón.</p>
                        <div className="lineaCorta"></div>
                        <h5>ACABADO</h5>
                        <p>Estándar (Espiral). Se ve la espiral resultante de la fabricación del encofrado.</p> 
                        <p>Liso. El interior está revestido por un formato de Kraft y polietileno mediante el cual se obtiene una textura totalmente lisa. Ofrece un acabado estéticamente impecable.</p>
                        <div className="lineaCorta"></div>
                        <h5>LONGITUD</h5>
                        <p>Llegue tan alto como quiera subir. Solo con un simple serrucho para alargar o acortar el encofrado a la medida deseada, luego, con cinta adhesiva, junte los dos cuerpos y el molde estará listo para encofrar.</p>
                        <div className="lineaCorta"></div>
                        <h5>RAPIDEZ</h5>
                        <p>Consiste en un alambre acerado, fijado a dos extremos del tubo y adherido a la pared interior qe garantiza un perfecto y rápido desencofrado.</p>
                        <div className="lineaCorta"></div>
                        <h5>PROTECCIÓN</h5>
                        <p>La composición especial de Encomax, permite conservar el encofrado hasta finalizar la obra, evitando que se ensucie o deteriore el pilar.</p>
                        <div className="lineaCorta"></div>
                        <h5>ALMACENAJE</h5>
                        <p>Gracias a su impermeabilidad y resistencia, no precisa almacenaje especial.</p>
                        <div className="lineaCorta"></div>
                        <h5>LIGEREZA</h5>
                        <p>Sin apenas esfuerzo físico en su manipulación y colocación.</p>
                    </div>
                </div>
            </div>

        </>
    )
}

const banner = 
[
    {id: 1, title: 'Sistema', img: '../../../assets/img/banner/slider01.jpg', alt: 'Imagen Banner' },
];

const galleryImg = 
[
    { id:'img1', img: "../../../assets/img/gallery/img1.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img2', img: "../../../assets/img/gallery/img2.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img3', img: "../../../assets/img/gallery/img3.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img4', img: "../../../assets/img/gallery/img10.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img5', img: "../../../assets/img/gallery/img7.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img6', img: "../../../assets/img/gallery/img8.jpg", alt: "Imagen de obra de Columna encomax"},
];