import styled from "styled-components";

export default function Characteristics(){
    return (
        <StyledCharacteristics style={{margin: '5rem 0rem'}}>  
            <div className="container">
                <div className="row">
                    <h2 className="contenTitle">RÁPIDO, PRÁCTICO Y ECONÓMICO</h2>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="contenInfo">
                            <img src="../../../assets/img/characteristics/rapido.svg" alt="logo característica rápido" />
                            <h4>RAPIDEZ DE INSTALACIÓN</h4>
                            <p>El sistema más rápido para encofrado de columnas sin necesidad de utilización de grúas. Por su ligereza se minimiza el esfuezo físico de manipulación.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="contenInfo">
                            <img src="../../../assets/img/characteristics/economico.svg" alt="logo característica económico" />
                            <h4>SOLUCIÓN ECONÓMICA</h4>
                            <p>La mejor relación costo beneficio de encofrado del mercado. Por su bajo costo, permite la realización simultánea de todos los pilares de la obra. Gran reducción de tiempos de obra.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="contenInfo">
                            <img src="../../../assets/img/characteristics/calidad.svg" alt="logo característica calidad" />
                            <h4>ACABADO DE CALIDAD</h4>
                            <p>Al ser una sola pieza no existe riesgo de rotura entre capas y se evitan fugas de hormigón. Además pueden elegirse opciones de texturas para el acabado final del hormigón.</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyledCharacteristics>
    )
}

const StyledCharacteristics = styled.div`

    .contenInfo{
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contenInfo img{
        width: 9rem;
        margin: 3rem 0rem;
    }
    
    .contenInfo h4 {
        font-size: 1.6rem;
    }
    .contenInfo p {
        font-size: 1rem;
        margin-top: 1rem;
    }
`