import Banner from "../../component/banner/Banner";
import Columns from "../../component/columns/Columns";
import Accessory from "../../component/columnsAccessory/Accessory";
import Puntal from "../../component/columnPuntal/Puntal";

export default function ColumnView (){

    return (
        <>
         <Banner banner={banner}/>
         <Columns/>
         <Accessory/>
        </>
    )
}

const banner = 
[
    {id: 1, title: 'Columnas', img: '../../../assets/img/banner/slider01.jpg', alt: 'Imagen Banner' },
];