import React from 'react';
import Styled from "styled-components";
import ApiDolar from '../../service/ApiDolar';

const AccessoryList = ({ accessorys, img, alt }) => {
    
    const {dolar, euro} = ApiDolar();

    const toThousand = n => {
        const parts = n.toString().split('.');
        const integerPart = parts[0];
        const decimalPart = parts[2] || '';
        
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const result = decimalPart ? `${formattedInteger},${decimalPart}` : formattedInteger;
        
        return result;
    };

    return(
        <StyleAccessoryList>
            <div className="scroll">
                <div className="container">
                    <div className="contenedorTabla">
                        <div className="table-container">
                            <table class="table table-striped">
                                <thead>
                                    <tr className='table-dark'>
                                        <th scope="col"></th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Código</th>
                                        <th scope="col">Material</th>
                                        <th scope="col">Largo</th>
                                        <th scope="col">Venta Dolar</th>
                                        <th scope="col">Venta Pesos</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    {accessorys.map(accessory => (
                                        <tr key={accessory.id}>
                                            <th><img className='imgProduct' src={img} alt={alt}></img></th>
                                            <td>{accessory.nombre_producto}</td>
                                            <td>{accessory.codigo}</td>
                                            <td>ABS + Fibra</td>
                                            <td>1 metro</td>
                                            <td>U$S {toThousand((accessory.prices[0].precio_venta*euro)/dolar)} +iva</td>
                                            <td>$ {toThousand(((accessory.prices[0].precio_venta*euro)/dolar) * dolar)} +iva</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </StyleAccessoryList>
    );
};

export default AccessoryList;

const StyleAccessoryList = Styled.div`
    .containerTableColumn{
        background-color: var(--colorGrisSecundario);
        padding: 4rem 0rem;
    }

    .imgProduct{
        width: 50px;
        @media (min-width: 992px){
            width: 100px;
        }
    }
    .col1{
        display:none;
    }
`