import Styled from "styled-components";

export default function Banner(props){
    return(
        <StyledBanner>  
            {props.banner.map((data) => (
                <div className="containerBanner" style={{ backgroundImage: `url(${data.img})` }}>
                    <p className="titleBanner">{data.title}</p>
                </div>
            ))}
        </StyledBanner>
    )
}

const StyledBanner = Styled.div`
    .containerBanner{
        height: 10rem;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: -80px;
        margin-bottom: 2rem;
        //css para texto
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f1f1f1;
        @media (min-width: 576px){
            background-position-y: -150px;
        }
        @media (min-width: 768px){
            background-position-y: -200px;
        }
        @media (min-width: 992px){
            background-position-y: -250px;
        }
        @media (min-width: 1200px){
            background-position-y: -300px;
        }
        @media (min-width: 1400px){
            background-position-y: -480px;
        }
    }
    .titleBanner{
        font-size: 1.8rem;
    }
`