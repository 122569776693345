import styled from "styled-components";
import Banner from "../../component/banner/Banner";

export default function MountingView(){
    return (
        <StyledMountingView>
            <Banner banner={banner}/>
            <div className="container">
                <h2 className="contenTitle" style={{margin: '3rem 0rem'}}>MONTAJE / COLOCACIÓN:</h2>
                <div className="row">
                    <div className="col-12">
                        <div className="containerCart">

                            <div className="card">
                                <div className="pasos">1</div>
                                <img src="../../../assets/img/mounting/mpaso1.jpg" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4><strong>1. Fijación de la base</strong></h4>
                                    <p className="card-text">Centrar y fijar la columna en la base para evitar el desplazamiento lateral en el momento de llenado.</p>
                                </div>
                            </div>

                            <div className="card">
                            <div className="pasos">2a</div>
                                <img src="../../../assets/img/mounting/mpaso2.jpg" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4><strong>2. Apuntalamiento caso 1</strong></h4>
                                    <p className="card-text">Apuntalar en forma lateral y nivelar. En función de la disposición de la columna puede tener 1 ó 2 puntales para evitar el movimiento en cualquier dirección.</p>
                                </div>
                            </div>

                            <div className="card">
                            <div className="pasos">2b</div>
                                <img src="../../../assets/img/mounting/mpaso2b.jpg" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4><strong>2b. Apuntalamiento caso 2</strong></h4>
                                    <p className="card-text">En caso de que la columna supere los 5 metros, es necesario poner dos hileras de puntales.</p>
                                </div>
                            </div>

                            <div className="card">
                            <div className="pasos">3</div>
                                <img src="../../../assets/img/mounting/mpaso4.jpg" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4><strong>3. Verificación de posición</strong></h4>
                                    <p className="card-text">Verificar la posición de la columna.</p>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>

                <h2 className="contenTitle" style={{margin: '3rem 0rem'}}>DESENCOFRADO:</h2>
                <div className="row">
                    <div className="col-12">
                        <div className="containerCart">

                            <div className="card">
                                <div className="pasos">1</div>
                                <img src="../../../assets/img/mounting/mpaso1.jpg" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4><strong>1. Retirar los puntales</strong></h4>
                                    <p className="card-text">Luego de que el hormigón haya fraguado, retirar los accesorios y cortar la columna con un cutter sobre la cinta.</p>
                                </div>
                            </div>

                            <div className="card">
                            <div className="pasos">2a</div>
                                <img src="../../../assets/img/mounting/mpaso2.jpg" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4><strong>2. Desencofrado</strong></h4>
                                    <p className="card-text">Retirar el encofrado Encomax.</p>
                                </div>
                            </div>

                            <div className="card">
                            <div className="pasos">3</div>
                                <img src="../../../assets/img/mounting/mpaso4.jpg" className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4><strong>3. Columna terminada</strong></h4>
                                    <p className="card-text">Resultado final, una columna perfecta con un acabado brilloso único.</p>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </StyledMountingView>
    )
}

const banner = 
[
    {id: 1, title: 'Montaje', img: '../../../assets/img/banner/slider01.jpg', alt: 'Imagen Banner' },
];

const StyledMountingView = styled.div`
    .containerCart{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        @media (min-width: 768px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 1200px){
            grid-template-columns: repeat(4, 1fr);
        }
    }
    .card{
        margin: 1rem 0rem;
        @media (min-width: 768px){
            margin: 1.5rem 1rem;
        }
    }
    .pasos{
        position: absolute;
        margin: 5px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: var(--colorPrincipal); // Cambia esto según el color que desees
        display: flex;
        justify-content: center;
        align-items: center;
        color: FFF;
        font-weight: bold;
        font-size: 1.8rem;
        z-index: 1; // Ajusta el índice z si es necesario
    }
`