import { useState } from "react";

import styled from "styled-components";
import AccessoryZuncho from "./AccessoryZuncho";
import AccessorySoporte from "./AccessorySoporte";
import ColumnPuntal from "./ColumnPuntal";


export default function Accessory(){

    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentSelection = (component) => {
        setSelectedComponent(component);
    };

    return(
        <StyledAccessory>
            <div className="container" style={{marginBottom: '5rem'}}>
                <div className="row" style={{marginTop: '3rem', textAlign: 'center'}}>
                    <div className="col-6 col-lg-3" >
                        <div className="contenImg">
                            <img src="../../../assets/img/products/zuncho.jpeg" alt="zuncho" />
                        </div>
                        <h4>Zuncho Encomax</h4>
                        <button className="btn btn-primary" onClick={() => handleComponentSelection(<AccessoryZuncho />)}>Ver Más</button> 
                    </div>
                
                    <div className="col-6 col-lg-3">
                        <div className="contenImg">
                            <img src="../../../assets/img/products/soporte.jpeg" alt="soporte" />
                        </div>
                            <h4>Soporte Encomax</h4>
                            <button className="btn btn-primary" onClick={() => handleComponentSelection(<AccessorySoporte />)}>Ver Más</button>
                    </div>

                    <div className="col-6 col-lg-3">
                        <div className="contenImg">
                            <img src="../../../assets/img/products/puntal.jpeg" alt="puntal inclinado" />
                        </div>
                            <h4>Puntal Inclinado</h4>
                            <button className="btn btn-primary" onClick={() => handleComponentSelection(<ColumnPuntal />)}>Ver Más</button>
                    </div>
                               
                </div>
            </div>
            {selectedComponent && (
                <div className="">
                    <div className="row" style={{margin: '0px'}}>
                        <div className="col-12">
                            {selectedComponent}
                        </div>
                    </div>
                </div>
            )}
        </StyledAccessory>
    )
}

const StyledAccessory = styled.div`
    .contenImg{
        margin-bottom: 2rem;
        width: 100%;
    }
    img {
        width: 100%;
    }
    .btn-primary{
        margin-top: 1rem;
        background-color: var(--colorPrincipal);
        color: #000;
        border: none;
    }
`