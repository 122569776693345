import ApiProducts from "../../service/ApiProducts";
import Spinner from "../spinner/Spinner";
import ColumnList from "./ColumnList";

export default function ColumnCuadrada() {
    const urlCirculares = 'https://sinis.com.ar/api/grupos-sistemas/grupo/sistema/34/productos';

    const { products, loading } = ApiProducts({ url: urlCirculares });
    const img = '../../../assets/img/products/cuadrada.jpeg';

    if (loading) {
        return <Spinner />;
    }

    return (
        // <Spinner />
        <>
            <ColumnList columns={products} img={img} />
        </>
    );
}