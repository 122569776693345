import GalleryImg from "./GalleryImg";

export default function Gallery (){
    
    return (
        <div className="container-fluid" style={{backgroundColor: 'var(--colorFondoPrincipal)', paddingBottom: '4rem'}}>
            <div className="row" style={{ justifyContent: 'center' }}>
                <h2 className="contenTitle" style={{margin: '4rem 0rem'}}>OBRAS DE COLUMNAS ENCOMAX</h2>
                <GalleryImg gallery={galleryImg} style={galleryImgStyle}/>
            </div>
        </div>
    )
}

const galleryImg = 
[
    { id:'img1', img: "../../../assets/img/gallery/img1.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img2', img: "../../../assets/img/gallery/img2.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img3', img: "../../../assets/img/gallery/img3.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img4', img: "../../../assets/img/gallery/img4.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img5', img: "../../../assets/img/gallery/img5.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img6', img: "../../../assets/img/gallery/img6.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img7', img: "../../../assets/img/gallery/img7.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img8', img: "../../../assets/img/gallery/img8.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img9', img: "../../../assets/img/gallery/img9.jpg", alt: "Imagen de obra de Columna encomax"},
    { id:'img10', img: "../../../assets/img/gallery/img10.jpg", alt: "Imagen de obra de Columna encomax"},
];

const galleryImgStyle = { display: 'flex', flexDirection: 'row', flexWrap: 'wrap'};